<template>
  <Banner />
  <Join />
  <About />
  <Contact />
  <Footer />
  <CopyRight/>
</template>

<script>

import Banner from '@/components/banner.vue'
import Join from '@/components/about/join.vue'
import About from '@/components/about/about.vue'
import Contact from '@/components/about/contact.vue'
import Footer from '@/components/footer.vue'
import CopyRight from '@/components/copy-right.vue'
export default {
  components: {
    Banner,
    Join,
    About,
    Contact,
    CopyRight,
    Footer
  }
}
</script>
