<template>
  <HostLayoutContainer class="matrix" :style="{height:'676px'}">
    <HostLayoutItem>
      <Title :title="{text: '关于我们', marginTop: '104px', marginBottom: '133px'}" id="about"/>
      <!--<div class="matrix__title">关于我们</div>-->
      <CardsRow :style="{height: '490px'}">
        <Row class="matrix__row">
          <Item class="matrix__item">
            <Card1 :logo="logo">
              <template #logo><img src="/assets/about/icon_1@3x.png" alt=""></template>
              <template #logo-active><img src="/assets/about/icon_1_active@3x.png" alt=""></template>
              <template #title>云计算顶尖团队</template>
              <template #desc>
                核心团队来自于美团、滴滴、豌豆荚、中国电信等公司。
              </template>
            </Card1>
          </Item>
          <Item class="matrix__item">
            <Card1 :logo="logo">
              <template #logo><img src="/assets/about/icon_2@3x.png" alt=""></template>
              <template #logo-active><img src="/assets/about/icon_2_active@3x.png" alt=""></template>
              <template #title>云计算生态广泛合作</template>
              <template #desc>
                Ubang与国内外众多科技服务提供商深度合作，共建云计算生态，助力企业客户 <br />
                实现智能化、数字化转型升级
              </template>
            </Card1>
          </Item>
          <Item class="matrix__item">
            <Card1 :logo="logo">
              <template #logo><img src="/assets/about/icon_3@3x.png" alt=""></template>
              <template #logo-active><img src="/assets/about/icon_3_active@3x.png" alt=""></template>
              <template #title>自主研发核心产品</template>
              <template #desc>
                Ubang云管产品及混合云产品完全自主研发 <br />
                并且持续开源开放打造技术品牌 <br />
                帮助客户高效自动化运维，节约成本 <br />
              </template>
            </Card1>
          </Item>
        </Row>
      </CardsRow>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import { defineComponent } from 'vue'
import Title from '@/components/title/title-with-underline.vue'
import CardsRow from '@/components/cards/cards-row.vue'
import Row from '@/components/cards/row.vue'
import Item from '@/components/cards/item.vue'
import Card1 from '@/components/cards/card1.vue'
export default defineComponent({
  components: {
    Title,
    CardsRow,
    Row,
    Item,
    Card1,
  },
  setup() {
    return {
      logo: {size: '150px', marginBottom: '21px', marginTop: '37px'}
    }
  }
})
</script>

<style lang="scss" scoped>
.matrix {
}
//.matrix__title {
//  margin-bottom: 110px;
//  margin-top: 65px;
//
//  display: flex;
//  justify-content: center;
//
//  font-size: 32px;
//  font-family: PingFangSC-Medium, PingFang SC;
//  font-weight: 500;
//  color: #0D253E;
//  line-height: 45px;
//  letter-spacing: 2px;
//}
.matrix__cards {}
.matrix__row {
  display: flex;
  justify-content: space-evenly;
}
.matrix__item {
  width: 346px;
  height: 361px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(70, 83, 110, 0.29);
  border-radius: 5px;
}
</style>
