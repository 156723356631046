<template>
  <HostLayoutContainer class="banner1">
    <HostLayoutItem>
      <JoinUs>
        <Title :title="{text: '加入我们', marginBottom: '106px'}" id="join-us"/>
      </JoinUs>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import Title from '@/components/title/title-with-underline.vue'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {Title},
})
</script>
