<template>
  <div class="card" @mouseenter="changeHover(true)" @mouseleave="changeHover(false)">
    <div class="card__logo">
      <div class="card__logo-inner" :style="{ width: logo.width, height: logo.height, marginBottom: logo.marginBottom, marginTop: logo.marginTop }">
        <slot v-if="!hover" name="logo" />
        <slot v-else name="logo-active" />
      </div>
    </div>
    <div class="card__title" :style="{ marginBottom: title.marginBottom }">
      <slot name="title" />
    </div>
    <div class="card__desc">
      <div class="card__desc--inner">
        <slot name="desc" />
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from 'vue'
export default defineComponent({
  props: ['logo'],
  setup(props) {
    const hover = ref(false);
    const {logo = {}, title = {}} = props;
    let {size, width, height, marginBottom, marginTop} = logo
    if (size) {
      width = size
      height = size
    }
    const changeHover = (isHover) => {
      hover.value = isHover
    }
    return {
      hover,
      changeHover,
      logo: {width, height, marginBottom, marginTop},
      title,
    }
  }
})
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
  cursor: pointer;
}
.card__logo {
  display: flex;
  justify-content: center;
}
.card__title {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 28px;
}
.card__desc {
  display: flex;
  justify-content: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #62768E;
  line-height: 26px;
}
.card__desc--inner {
  text-align: center;
  width: 285px;
}
</style>
