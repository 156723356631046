<template>
  <div class="cards">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
